import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import ReactGA from 'react-ga4';
import { MBAL_QR_URL } from "./Constants";

ReactGA.initialize(window?.config?.gaMeasurementId);

function noop() { }

if (process.env.NODE_ENV !== "development") {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

declare global {
  interface Window {
    ReactNativeWebView?: any;
    config?: any;
  }
}
export const QR_URL = process.env.NODE_ENV !== "production" ?
  process.env.REACT_APP_QR_URL : (process.env.REACT_APP_QR_URL || MBAL_QR_URL);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
