import { useState, useEffect, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "src/styles/BuyInsurance.module.scss";
import { useTranslation } from "react-i18next";
import InsuranceItem from "src/components/InsuranceItem";
import {
  BUY_INSURANCE_STEP_DATA,
  CARD_TYPE_VALUES,
  CardType,
  GenderDisplayMap,
  OTP_LENGTH,
  MBAGEAS_LIFE_URL,
  CardTypeSortDisplayMap,
  INSURANCE_PAYMENT_GUIDE_URL,
  MB_QR_URL,
  PAYMENT_CARD_TYPE,
} from "src/Constants";
import HorizontalStepper from "src/components/HorizontalStepper";
import Accordion from "react-bootstrap/Accordion";
import Input from "src/components/Input";
import Checkbox from "src/components/Checkbox";
import UiService from "src/services/UiService";
import OtpPopup from "src/components/OtpPopup";
import { useHistory } from "react-router-dom";
import mailImg from "src/assets/images/mail.svg";
import phoneImg from "src/assets/images/phone.svg";
import locationImg from "src/assets/images/location.svg";
import checkCircleBlueImg from "src/assets/images/check-circle-blue.svg";
import Utils from "src/utils/Utils";
import { createOtp, createOrder, getBanks, sendOrderPayment } from "src/api";
import FullCancerFormService from "../FullCancerFormService";
import Item from "src/models/Item";
import { useMediaQuery } from "react-responsive";
import ImageInput from "src/components/ImageInput";
import infoIconGray from "src/assets/images/information-circle-gray.svg";
import QRCode from "react-qr-code";
import PopupConfirm from "src/components/PopupConfirm";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import DropdownInput, { DropdownItem } from "src/components/DropdownInput";
import HighlightText from "src/components/HighlightText";
import congratulationImg from "src/assets/images/congratulation.svg";
import RadioItemSelector, { RadioItem } from "src/components/RadioItemSelector";
import Bank from "src/models/Bank";
import radioCheckedImg from "src/assets/images/radio-checked.svg";
import radioDefaultImg from "src/assets/images/radio-default.svg";
import { QR_URL } from "src";
import AuthInfoService from "src/services/AuthInfoService";
dayjs.extend(customParseFormat);

interface ConfirmInfoState {
  phone: string;
  errPhone: string;
  email: string;
  errEmail: string;
  address: string;
  errAddress: string;
  agreeJoin: boolean;
  agreeToc: boolean;
  requestId?: string | null;
}

const ConfirmInfo = () => {
  const isLargeDevice = useMediaQuery({
    query: "(min-width: 992px)",
  });
  const history = useHistory();
  const { t } = useTranslation();
  const [values, setValues] = useState<ConfirmInfoState>({
    phone: "",
    errPhone: "",
    email: "",
    errEmail: "",
    address: "",
    errAddress: "",
    agreeJoin: false,
    agreeToc: false,
    requestId: null,
  });
  const [commitments, setCommitments] = useState<Item[]>();
  const [showOtpPopup, setShowOtpPopup] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>();
  const [errOtp, setErrOtp] = useState<string>();
  const [otpKeyId, setOtpKeyId] = useState<number>(1);
  const [frontIdCard, setFrontIdCard] = useState<File>();
  const [errFrontIdCard, setErrFrontIdCard] = useState<string>();
  const [backIdCard, setBackIdCard] = useState<File>();
  const [errBackIdCard, setErrBackIdCard] = useState<string>();
  const [signature, setSignature] = useState<File>();
  const [errSignature, setErrSignature] = useState<string>();
  const [applNo, setApplNo] = useState<string>();
  const [qrPaymentMethod, setQrPaymentMethod] = useState<string>("MBAL_QR");
  const [paymentMethod, setPaymentMethod] = useState<string>("QR");
  const [showPaymentPopup, setShowPaymentPopup] = useState<boolean>(false);
  const [banks, setBanks] = useState<Bank[]>();
  const [selectedBank, setSelectedBank] = useState<number>();
  const [orderData, setOrderData] = useState<any>();
  const [paymentCardType, setPaymentCardType] = useState(
    PAYMENT_CARD_TYPE.DOMESTIC.toString()
  );
  const _loadCommitments = () => {
    const commitmentsStr = localStorage.getItem("cancer_commitments");
    if (!commitmentsStr) return;
    try {
      setCommitments(JSON.parse(commitmentsStr));
    } catch (err) {
      console.log("Parse cancer commitments err", err);
    }
  };

  const _fillData = () => {
    if (FullCancerFormService.getFullCancerFormInfo()?.email) {
      setValues({
        ...values,
        email: FullCancerFormService.getFullCancerFormInfo()?.email || "",
        phone: FullCancerFormService.getFullCancerFormInfo()?.phone || "",
        address: FullCancerFormService.getFullCancerFormInfo()?.address || "",
      });
    }
    if (FullCancerFormService.frontIdCard) {
      setFrontIdCard(FullCancerFormService.frontIdCard);
    }
    if (FullCancerFormService.backIdCard) {
      setBackIdCard(FullCancerFormService.backIdCard);
    }
    if (FullCancerFormService.signature) {
      setSignature(FullCancerFormService.signature);
    }
  };

  const _loadBanks = async () => {
    try {
      const banks = await getBanks(
        FullCancerFormService.getFullCancerFormInfo()?.idCardNo || ""
      );
      console.log("Banks", banks);
      setBanks(banks.data?.list);
      setSelectedBank(banks.data?.list?.[0]?.bpmId);
    } catch (err) {
      console.log("Load bank err: ", err);
    }
  };

  useEffect(() => {
    _loadCommitments();
    _fillData();
    _loadBanks();
  }, []);

  const _validate = (): boolean => {
    let isValid: boolean = true;
    let errEmail = "";
    let errPhone = "";
    let errAddress = "";
    if (!values.email || values.email.trim() === "") {
      errEmail = t("email-required");
      isValid = false;
    } else if (!Utils.isValidEmail(values.email)) {
      errEmail = t("invalid-email");
      isValid = false;
    }
    if (!values.phone || values.phone.trim() === "") {
      errPhone = t("phone-required");
      isValid = false;
    } else if (!Utils.isValidPhoneNumer(values.phone)) {
      errPhone = t("invalid-phone");
      isValid = false;
    }
    if (!values.address || values.address.trim() === "") {
      errAddress = t("address-required");
      isValid = false;
    }
    if (!FullCancerFormService.getFullCancerFormInfo()?.hasCustomerInfo) {
      if (!frontIdCard) {
        setErrFrontIdCard(
          Utils.getReplaceText(
            t("front-id-card-required"),
            _getCardTypeSortDisplay(
              FullCancerFormService.getFullCancerFormInfo()?.idCardType
            )
          )
        );
        isValid = false;
      } else if (!Utils.isValidImageFileType(frontIdCard)) {
        setErrFrontIdCard(t("invalid-image-type"));
        isValid = false;
      } else if (!Utils.isValidUploadImageSize(frontIdCard)) {
        setErrFrontIdCard(t("invalid-image-size"));
        isValid = false;
      }
      if (
        FullCancerFormService.getFullCancerFormInfo()?.idCardType !==
        CardType.PASSPORT
      ) {
        if (!backIdCard) {
          setErrBackIdCard(
            Utils.getReplaceText(
              t("back-id-card-required"),
              _getCardTypeSortDisplay(
                FullCancerFormService.getFullCancerFormInfo()?.idCardType
              )
            )
          );
          isValid = false;
        } else if (!Utils.isValidImageFileType(backIdCard)) {
          setErrBackIdCard(t("invalid-image-type"));
          isValid = false;
        } else if (!Utils.isValidUploadImageSize(backIdCard)) {
          setErrBackIdCard(t("invalid-image-size"));
          isValid = false;
        }
      }

      if (!signature) {
        setErrSignature(t("signature-required"));
        isValid = false;
      } else if (!Utils.isValidImageFileType(signature)) {
        setErrSignature(t("invalid-image-type"));
        isValid = false;
      } else if (!Utils.isValidUploadImageSize(signature)) {
        setErrSignature(t("invalid-image-size"));
        isValid = false;
      }
    }

    if (!isValid) {
      setValues({
        ...values,
        errEmail,
        errPhone,
        errAddress,
      });
      return false;
    }

    if (!values.agreeJoin || !values.agreeToc) {
      UiService.openPopup({
        showIcon: false,
        content: t("please-agree-toc"),
        onlyOkButton: true,
        okTitle: t("agree"),
        onOk: () => {
          UiService.closePopup();
        },
        onCancel: () => {
          UiService.closePopup();
        },
      });
      isValid = false;
    }

    return isValid;
  };

  const _handleCreateOrder = async () => {
    try {
      UiService.showLoading();
      const frontIdCardBase64Tmp =
        FullCancerFormService.getFullCancerFormInfo()?.hasCustomerInfo === true
          ? null
          : await Utils.getFileBase64(FullCancerFormService.frontIdCard!);
      const backIdCardBase64Tmp =
        FullCancerFormService.getFullCancerFormInfo()?.hasCustomerInfo === true
          ? null
          : FullCancerFormService.getFullCancerFormInfo()?.idCardType ===
            CardType.PASSPORT
          ? null
          : await Utils.getFileBase64(FullCancerFormService.backIdCard!);
      const signatureBase64Tmp =
        FullCancerFormService.getFullCancerFormInfo()?.hasCustomerInfo === true
          ? null
          : await Utils.getFileBase64(FullCancerFormService.signature!);
      const frontIdCardBase64 = Utils.getBase64Content(frontIdCardBase64Tmp??'');
      const backIdCardBase64 = Utils.getBase64Content(backIdCardBase64Tmp??'');
      const signatureBase64 = Utils.getBase64Content(signatureBase64Tmp??'');
      const selectedPackageData =
        FullCancerFormService.getInsurancePackages()?.find(
          (item) =>
            item.id ===
            FullCancerFormService.getFullCancerFormInfo()?.selectedPackage
        );

      const createOrderParams = {
        verificationOtp: {
          requestId: values.requestId,
          otp,
        },
        dob: FullCancerFormService.getFullCancerFormInfo()?.dob,
        gender: FullCancerFormService.getFullCancerFormInfo()?.gender,
        fullname: FullCancerFormService.getFullCancerFormInfo()?.fullname,
        premium: selectedPackageData?.premiumAm,
        premiumAfterDiscount:
          typeof FullCancerFormService.getFullCancerFormInfo()?.afterDiscount !=
          "undefined"
            ? FullCancerFormService.getFullCancerFormInfo()?.afterDiscount
            : selectedPackageData?.premiumAm,
        sumInsured: selectedPackageData?.insamountAm,
        // voucherCode: FullCancerFormService.getFullCancerFormInfo()?.voucherCode,
        idCardNo: FullCancerFormService.getFullCancerFormInfo()?.idCardNo,
        idCardType: FullCancerFormService.getFullCancerFormInfo()?.idCardType,
        phone: values.phone,
        email: values.email,
        nationality: "Việt Nam",
        address: values.address,
        issuedDate: FullCancerFormService.getFullCancerFormInfo()?.issuedDate,
        issuedPlace: FullCancerFormService.getFullCancerFormInfo()?.issuePlace,
        photoFront: frontIdCardBase64
          ? {
              fileType: Utils.getFileType(FullCancerFormService.frontIdCard!),
              fileBase64: frontIdCardBase64,
            }
          : null,
        photoBack: !backIdCardBase64
          ? null
          : FullCancerFormService.getFullCancerFormInfo()?.idCardType ===
            CardType.PASSPORT
          ? null
          : {
              fileType: Utils.getFileType(FullCancerFormService.backIdCard!),
              fileBase64: backIdCardBase64,
            },
        photoSignature: signatureBase64
          ? {
              fileType: Utils.getFileType(FullCancerFormService.signature!),
              fileBase64: signatureBase64,
            }
          : null,
        hasGetCustomerInfoSuccess:
          FullCancerFormService.getFullCancerFormInfo()?.hasCustomerInfo ===
          true,
        discount:
          FullCancerFormService.getFullCancerFormInfo()?.discount || null,
        channel: AuthInfoService.getChannel(),
      };
      console.log("createOrderParams", createOrderParams);
      const createOrderRes = await createOrder(createOrderParams);
      console.log("Create Order response", createOrderRes);
      setOrderData(createOrderRes?.data);
      setApplNo(createOrderRes?.data?.applNo);
      UiService.hideLoading();
      setShowOtpPopup(false);
      if (
        FullCancerFormService.getFullCancerFormInfo()?.discount &&
        FullCancerFormService.getFullCancerFormInfo()?.afterDiscount === 0
      ) {
        UiService.openPopup({
          onlyCancelButton: true,
          title: t("congratulation"),
          image: (
            <div className={styles.congratulationImage}>
              <img
                src={congratulationImg}
                width={180}
                height={148}
                alt="congratulation"
              />
            </div>
          ),
          titleClassName: "popupTitle textPrimary text-center mb-4",
          onCancel: () => {
            UiService.closePopup();
            FullCancerFormService.reset();
            window.location.replace(MBAGEAS_LIFE_URL);
          },
          cancelTitle: t("back-to-home"),
          children: (
            <div className={styles.congratulationContent}>
              <HighlightText
                text={t("congratulation-message")}
                replaceText={[createOrderRes?.data?.policyNumber || ""]}
                className={"body20"}
                highlightClassName={"body20 w600"}
              />
            </div>
          ),
        });
      } else {
        _showPaymentPopup();
      }
    } catch (err: any) {
      UiService.hideLoading();
      console.log("Create Order err", err?.response?.data);
      if (err?.response?.data?.code === 400) {
        if (err?.response?.data?.error_code === "MSG03") {
          setErrOtp(err?.response?.data?.error_data?.messsage);
        } else {
          setShowOtpPopup(false);
          UiService.openPopup({
            content: err?.response?.data?.error_data?.messsage,
            onlyOkButton: true,
            onOk: () => {
              UiService.closePopup();
            },
          });
        }
      }
    }
  };

  const _createOtp = async () => {
    try {
      UiService.showLoading();
      const createOtpRes = await createOtp(
        FullCancerFormService.getFullCancerFormInfo()?.idCardNo || "",
        values.phone,
        "BUY_INSURANCE_CANCER"
      );
      console.log("Create Otp res", createOtpRes);
      setValues({
        ...values,
        requestId: createOtpRes?.data?.requestId,
      });
      UiService.hideLoading();
      setErrOtp("");
      setOtpKeyId(otpKeyId + 1);
      setShowOtpPopup(true);
    } catch (err: any) {
      UiService.hideLoading();
      console.log("Create Otp err", err);
      if (err?.response?.data?.code === 400) {
        UiService.openPopup({
          content: err?.response?.data?.error_data?.messsage,
          onlyOkButton: true,
          onOk: () => {
            UiService.closePopup();
          },
        });
      }
    }
  };

  const _handleConfirmCreateOtp = async () => {
    UiService.closePopup();
    _createOtp();
  };

  const _handleContinue = () => {
    const isValid = _validate();
    if (!isValid) return;
    FullCancerFormService.saveFullCanncerFormInfo({
      phone: values.phone,
      email: values.email,
      address: values.address,
    });
    UiService.openPopup({
      title: t("confirm-info"),
      onlyOkButton: true,
      showClose: true,
      showIcon: false,
      children: (
        <div className={styles.popupChilren}>
          <div className="popupBody textBlack mb-4">
            {t("hint-create-otp-part1")}
            <span className="bold"> {t("confirm")} </span>
            {t("hint-create-otp-part2")}
          </div>
          <div className="rowStart fullWidth">
            <div className={styles.checkedIconContainer}>
              <img
                src={checkCircleBlueImg}
                width={24}
                height={24}
                alt={"checked"}
              />
            </div>
            <span className="popupBody textBlack">
              {t("confirm-phone-part1")}
              <span className="bold textPrimary"> {values.phone} </span>
              {t("confirm-phone-part2")}
            </span>
          </div>
        </div>
      ),
      onOk: _handleConfirmCreateOtp,
      onCancel: () => {
        UiService.closePopup();
      },
    });
  };

  const _handleGoBack = () => {
    history.goBack();
  };

  const _getDisplayCardType = (cardType?: CardType): string => {
    if (!cardType) return "";
    return t(
      CARD_TYPE_VALUES.find((item) => item.value === cardType)?.name || ""
    );
  };

  const _renderCustomerInfo = () => {
    return (
      <Accordion defaultActiveKey="0" className="mb-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className="appH4 textBlack">
              {t("fill-personal-info")}
              <span className="required">*</span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="mt-3 mb-3 body16 w600 textBlack">
              {t("customer-info")}
            </div>
            <div className="line mb-3" />
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("identity-card-type")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {_getDisplayCardType(
                    FullCancerFormService.getFullCancerFormInfo()?.idCardType
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("issue-date")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {FullCancerFormService.getFullCancerFormInfo()?.issuedDate ||
                    ""}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("issue-place")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {FullCancerFormService.getFullCancerFormInfo()?.issuePlace ||
                    ""}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("identity-card-no")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {FullCancerFormService.getFullCancerFormInfo()?.idCardNo}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("fullname")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {FullCancerFormService.getFullCancerFormInfo()?.fullname}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("gender")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {t(
                    GenderDisplayMap[
                      FullCancerFormService.getFullCancerFormInfo()?.gender ||
                        ""
                    ]
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("dob")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {FullCancerFormService.getFullCancerFormInfo()?.dob || ""}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="textGray body14">{t("nationality")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">Việt Nam</div>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const _handleChangeEmail = (e) => {
    setValues({
      ...values,
      email: e.target.value,
      errEmail: "",
    });
  };

  const _handleChangePhone = (e) => {
    setValues({
      ...values,
      phone: e.target.value,
      errPhone: "",
    });
  };

  const _handleChangeAddress = (e) => {
    setValues({
      ...values,
      address: e.target.value,
      errAddress: "",
    });
  };

  const _renderContactInfoForm = () => {
    return (
      <Accordion defaultActiveKey="1" className="mb-3">
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <div className="appH4 textBlack">
              {t("contact-info")}
              <span className="required">*</span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <Input
              label={t("email")}
              placeholder={t("email-placeholder")}
              required={true}
              leftIcon={mailImg}
              value={values.email}
              errorText={values.errEmail}
              onChange={_handleChangeEmail}
              maxLength={100}
            />
            <Input
              label={t("phone")}
              placeholder={t("phone-placeholder")}
              required={true}
              leftIcon={phoneImg}
              value={values.phone}
              errorText={values.errPhone}
              onChange={_handleChangePhone}
              maxLength={20}
            />
            <Input
              label={t("address")}
              placeholder={t("address-placeholder")}
              required={true}
              leftIcon={locationImg}
              value={values.address}
              errorText={values.errAddress}
              onChange={_handleChangeAddress}
              maxLength={100}
            />
            <div className="mb-3 body16 textBlack italic">
              <span className="w700">{t("note")} </span>
              {t("contact-info-note")}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const _handleChangeFrontIdCard = useCallback(
    (file: File) => {
      // console.log("File Size", e.target.files[0]);
      setFrontIdCard(file);
      if (!Utils.isValidImageFileType(file)) {
        setErrFrontIdCard(t("invalid-image-type"));
      } else if (!Utils.isValidUploadImageSize(file)) {
        setErrFrontIdCard(t("invalid-image-size"));
      } else {
        setErrFrontIdCard("");
        FullCancerFormService.frontIdCard = file;
      }
    },
    [setFrontIdCard, setErrFrontIdCard, t]
  );

  const _handleChangeBackIdCard = useCallback(
    (file: File) => {
      setBackIdCard(file);
      if (!Utils.isValidImageFileType(file)) {
        setErrBackIdCard(t("invalid-image-type"));
      } else if (!Utils.isValidUploadImageSize(file)) {
        setErrBackIdCard(t("invalid-image-size"));
      } else {
        setErrBackIdCard("");
        FullCancerFormService.backIdCard = file;
      }
    },
    [setBackIdCard, setErrBackIdCard, t]
  );

  const _handleChangeSignature = useCallback(
    (file: File) => {
      setSignature(file);
      if (!Utils.isValidImageFileType(file)) {
        setErrSignature(t("invalid-image-type"));
      } else if (!Utils.isValidUploadImageSize(file)) {
        setErrSignature(t("invalid-image-size"));
      } else {
        setErrSignature("");
        FullCancerFormService.signature = file;
      }
    },
    [setSignature, setErrSignature, t]
  );
  const _getCardTypeSortDisplay = (cardType?: CardType | null) => {
    if (!cardType) return "";
    return t(CardTypeSortDisplayMap[cardType]);
  };

  const _renderIdentityCardForm = () => {
    if (FullCancerFormService.getFullCancerFormInfo()?.hasCustomerInfo)
      return <div />;
    return (
      <Accordion defaultActiveKey="1" className="mb-3">
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <div className="appH4 textBlack">
              {t("identity-card")}
              <span className="required">*</span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            {FullCancerFormService.getFullCancerFormInfo()?.idCardType !==
            CardType.PASSPORT ? (
              <>
                <Row>
                  <Col sm className="mb32">
                    <ImageInput
                      file={frontIdCard}
                      label={Utils.getReplaceText(
                        t("front-id-card-img"),
                        _getCardTypeSortDisplay(
                          FullCancerFormService.getFullCancerFormInfo()
                            ?.idCardType
                        )
                      )}
                      onChange={_handleChangeFrontIdCard}
                      errText={errFrontIdCard}
                    />
                  </Col>
                  <Col sm className="mb32">
                    <ImageInput
                      label={Utils.getReplaceText(
                        t("back-id-card-img"),
                        _getCardTypeSortDisplay(
                          FullCancerFormService.getFullCancerFormInfo()
                            ?.idCardType
                        )
                      )}
                      file={backIdCard}
                      errText={errBackIdCard}
                      onChange={_handleChangeBackIdCard}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ImageInput
                      label={t("signature-img")}
                      file={signature}
                      onChange={_handleChangeSignature}
                      imageClassName={styles.signatureImage}
                      imageContainerClassName={styles.signatureImageContainer}
                      errText={errSignature}
                      helperText={t("please-sign-to-paper")}
                      helperIcon={infoIconGray}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col sm className="mb32">
                    <ImageInput
                      file={frontIdCard}
                      label={Utils.getReplaceText(
                        t("front-id-card-img"),
                        _getCardTypeSortDisplay(
                          FullCancerFormService.getFullCancerFormInfo()
                            ?.idCardType
                        )
                      )}
                      onChange={_handleChangeFrontIdCard}
                      errText={errFrontIdCard}
                    />
                  </Col>
                  <Col sm className="mb32">
                    <ImageInput
                      label={t("signature-img")}
                      file={signature}
                      onChange={_handleChangeSignature}
                      errText={errSignature}
                      helperText={t("please-sign-to-paper")}
                      helperIcon={infoIconGray}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const _handleChangeAgreeJoin = (value: boolean) => {
    console.log("_handleChangeAgreeJoin", value);
    // setAgreeJoin(value);
    setValues({
      ...values,
      agreeJoin: value,
    });
  };

  const _handleChangeAgreeToc = (value: boolean) => {
    // setAgreeToc(value);
    setValues({
      ...values,
      agreeToc: value,
    });
  };

  const _renderTocCheckboxContent = () => {
    return (
      <span
        className={`ms-2 body16 w600 ${
          values.agreeToc ? "textBlack" : "textGray"
        }`}
      >
        {t("i-read-agree-with")}&nbsp;
        <a
          href="/cancer/term_and_condition"
          target="_blank"
          className={values.agreeToc ? styles.linkActive : styles.linkInactive}
        >
          {t("toc")}
        </a>
      </span>
    );
  };

  const _renderCommitment = () => {
    return (
      <Accordion defaultActiveKey="2" className="mb-3">
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <div className="appH4 textBlack">
              {t("commitment")}
              <span className="required">*</span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className={`${styles.commitmentContent} body14 mb-3`}>
              <div className="smallTitle16 mb-4">{t("commitment-title")}</div>
              {commitments &&
                commitments.map((item) => (
                  <div className="mb-4" key={item.id}>
                    {item.id}. {item.name}
                  </div>
                ))}
            </div>

            <div className="mb-3">
              <div className="mb-3 body16 textBlack italic">
                <span className="w700">{t("note")} </span>
                {t("commitment-note")}
              </div>
              <Checkbox
                text={t("i-agree-join-insurance")}
                checked={values.agreeJoin}
                onChange={_handleChangeAgreeJoin}
              />
            </div>

            <Checkbox
              // text={t("i-agree-toc")}
              textComponent={_renderTocCheckboxContent()}
              checked={values.agreeToc}
              onChange={_handleChangeAgreeToc}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const _renderButton = () => {
    return (
      <div className="rowStart">
        <Button variant="light" onClick={_handleGoBack} className="flex1 me-3">
          {t("go-back")}
        </Button>
        <Button variant="primary" onClick={_handleContinue} className="flex1">
          {t("confirm")}
        </Button>
      </div>
    );
  };

  const _renderInfo = () => {
    return (
      <div className="mb-lg-0 mb-4">
        <Row className="mb-4">
          <Col sm md={8} className="d-flex flex-column justify-content-center">
            <div className="appH3 textBlack">{t("confirm-info")}</div>
          </Col>
          <Col sm md={4} className="d-flex flex-column justify-content-center">
            <div className="body16 textSecondary text-left text-sm-end">
              {t("required-info")}
            </div>
          </Col>
        </Row>

        {_renderCustomerInfo()}
        {_renderContactInfoForm()}
        {_renderIdentityCardForm()}
        {_renderCommitment()}

        {/* <div className={styles.infoForm}></div> */}
        {isLargeDevice && _renderButton()}
      </div>
    );
  };

  const _renderInsuranceItem = () => {
    return (
      <div className="mb-3">
        <Row className="mb-4">
          <Col sm className="d-flex flex-column justify-content-center">
            <div className="appH3 textBlack">{t("choosed-product")}</div>
          </Col>
        </Row>
        <InsuranceItem
          data={FullCancerFormService.getInsurancePackages()?.find(
            (item) =>
              item.id ===
              FullCancerFormService.getFullCancerFormInfo()?.selectedPackage
          )}
          discount={
            FullCancerFormService.getFullCancerFormInfo()?.applyingDiscount
          }
          beforeDiscount={
            FullCancerFormService.getFullCancerFormInfo()?.beforeDiscount
          }
          discountAmt={
            FullCancerFormService.getFullCancerFormInfo()?.discountAmt
          }
          afterDiscount={
            FullCancerFormService.getFullCancerFormInfo()?.afterDiscount
          }
        />
      </div>
    );
  };

  const _handleConfirmOtp = () => {
    if (!otp || otp.length < OTP_LENGTH) {
      setErrOtp(t("otp-include-6-digit"));
      return;
    }
    _handleCreateOrder();
  };

  const _handleCancelOtp = () => {
    setShowOtpPopup(false);
    setErrOtp("");
  };

  const _handleChangeOtp = (value: string) => {
    if (value !== otp) {
      console.log("On Change Otp", value);
      setOtp(value);
      setErrOtp("");
    }
  };

  const _handleResendOtp = () => {
    _createOtp();
  };

  const _getMbalQrData = () => {
    const reformatDob = Utils.formatDate(
      FullCancerFormService.getFullCancerFormInfo()?.dob
        ? Utils.covertToDate(
            FullCancerFormService.getFullCancerFormInfo()?.dob!
          )
        : new Date(),
      "YYYY-MM-DD"
    );
    return `${QR_URL}?tab=LAN_DAU&code=${applNo}&date=${reformatDob}&date2=${reformatDob}`;
  };

  const _getMbAppQrData = () => {
    return MB_QR_URL;
  };

  const _handleChangeQrPaymentMethod = (value) => {
    setQrPaymentMethod(value);
  };

  const _showPaymentPopup = () => {
    setShowPaymentPopup(true);
  };

  const _get24hLaterText = () => {
    const after24h = dayjs().add(24, "hour");
    return `${after24h.format("HH")}h${
      after24h.minute() > 0 ? after24h.format("mm") : ""
    } ${t("daylc")} ${after24h.format(t("date-format"))}`;
  };

  const _handleChangePaymentMethod = (value: string) => {
    setPaymentMethod(value);
  };

  const _renderPaymentQr = () => {
    const selectedPackageData =
      FullCancerFormService.getInsurancePackages()?.find(
        (item) =>
          item.id ===
          FullCancerFormService.getFullCancerFormInfo()?.selectedPackage
      );
    const qrPaymentMethodData: DropdownItem[] = [
      {
        value: "MBAL_QR",
        name: t("pay-with-mbal"),
      },
      {
        value: "MB_QR",
        name: t("pay-with-mbapp"),
      },
    ];
    return (
      <>
        <DropdownInput
          values={qrPaymentMethodData}
          value={qrPaymentMethod}
          onChange={_handleChangeQrPaymentMethod}
        />
        <div className="columnCenter">
          <div className="smallTitle mb-2">{t("please-scan-qr")}</div>
          <div className="title16 w400 mb32">
            {t("ycbh-number2")}:{" "}
            <span className="textPrimary w700">{applNo}</span>.{" "}
            {t("money-amount")}:{" "}
            <span className="textPrimary w700">
              {FullCancerFormService.getFullCancerFormInfo()?.discountAmt &&
              FullCancerFormService.getFullCancerFormInfo()?.discount
                ? `${Utils.formatMoney(
                    FullCancerFormService.getFullCancerFormInfo()?.afterDiscount
                  )} ${t("vnd")}`
                : selectedPackageData?.premiumAmTxt}
            </span>
          </div>

          <QRCode
            size={200}
            value={
              qrPaymentMethod === "MBAL_QR"
                ? _getMbalQrData()
                : _getMbAppQrData()
            }
          />
          <div className="body14 textBlack mt-3 text-center">
            {t("detail-guide-about-qr")}
          </div>
          <a
            href={INSURANCE_PAYMENT_GUIDE_URL}
            target="_blank"
            rel="noreferrer"
            className="body14 textPrimary noDecoration"
          >
            {INSURANCE_PAYMENT_GUIDE_URL}
          </a>
        </div>
      </>
    );
  };

  const _handleSelectBank = (bpmId: number) => {
    setSelectedBank(bpmId);
  };

  const _renderBankItem = (item: Bank, index: number) => {
    const isSelected = item.bpmId === selectedBank;
    return (
      <Col key={item.bpmId} xs={12} lg={4} className="gx-2">
        <div
          onClick={() => _handleSelectBank(item.bpmId)}
          className={
            isSelected ? styles.radioItemActive : styles.radioItemInactive
          }
        >
          <img
            src={isSelected ? radioCheckedImg : radioDefaultImg}
            alt={"Radio "}
            className={styles.radioImg}
          />
          <div className="rowStart fullWidth">
            <div
              className={`flex1 body16 w600 mb-1 ${
                isSelected ? "textPrimary" : "textBlack"
              }`}
            >
              {item.bankShortName}
            </div>
            <img
              src={item.bankLogo}
              className={styles.bankLogo}
              alt={item.bankShortName}
            />
          </div>
        </div>
      </Col>
    );
  };

  const _handleChangePaymentCardType = (value?: string) => {
    if (value) {
      setPaymentCardType(value);
      if (banks && banks!.length > 0) {
        const bankList = banks.filter((item) => item.type === +value);
        if (bankList && bankList.length > 0) {
          setSelectedBank(bankList?.[0]?.bpmId);
        }
      }
    }
  };

  const _renderPaymentBank = () => {
    const paymentCardTypeData: DropdownItem[] = [
      {
        value: PAYMENT_CARD_TYPE.DOMESTIC.toString(),
        name: t("domestic-card"),
      },
      {
        value: PAYMENT_CARD_TYPE.CREDIT.toString(),
        name: t("credit-card"),
      },
    ];

    return (
      <>
        <DropdownInput
          values={paymentCardTypeData}
          value={paymentCardType}
          onChange={_handleChangePaymentCardType}
        />
        {paymentCardType === PAYMENT_CARD_TYPE.DOMESTIC.toString() && (
          <div className="body20 mb-3">{t("please-choose-bank")}</div>
        )}
        <Row>
          {banks &&
            banks
              .filter((item) => item.type === +paymentCardType)
              .map(_renderBankItem)}
        </Row>
      </>
    );
  };

  const _paymentWithBank = async () => {
    // setShowPaymentPopup(false);
    try {
      UiService.showLoading();
      const sendPaymentRes = await sendOrderPayment(
        orderData?.orderId,
        selectedBank || -1
      );
      console.log("sendPaymentRes", sendPaymentRes);
      // {"paymentOrderId":15700,"redirectUrl":"/payment/?oid=15700&checksum=1e0f6e12f518a36311421366f1269fabe64d5965","paymentUrl":"https://devtest.baokim.vn:9243/payment/?oid=15700&checksum=1e0f6e12f518a36311421366f1269fabe64d5965"}
      window.location.href = sendPaymentRes.data?.paymentUrl;
    } catch (err: any) {
      setShowPaymentPopup(false);
      UiService.hideLoading();
      console.log("Create Order err", err?.response?.data);
      if (err?.response?.data?.code === 400) {
        if (err?.response?.data?.error_code === "MSG03") {
          setErrOtp(err?.response?.data?.error_data?.messsage);
        } else {
          setShowOtpPopup(false);
          UiService.openPopup({
            content: err?.response?.data?.error_data?.messsage,
            onlyOkButton: true,
            onOk: () => {
              UiService.closePopup();
            },
          });
        }
      }
    }
  };

  const _renderPaymentPopup = () => {
    const selectedPackageData =
      FullCancerFormService.getInsurancePackages()?.find(
        (item) =>
          item.id ===
          FullCancerFormService.getFullCancerFormInfo()?.selectedPackage
      );

    const paymentMethodData: RadioItem[] = [
      {
        value: "QR",
        name: t("pay-with-qr"),
      },
      {
        value: "BANK",
        name: t("online-payment"),
      },
    ];

    return (
      <PopupConfirm
        largePopup={true}
        visible={showPaymentPopup}
        onlyOkButton={true}
        title={t("payment")}
        showIcon={false}
        showClose={false}
        titleClassName={"popupTitle2 textBlack flex1 mb-4 rowStart fullWidth"}
        onOk={() => {
          if (paymentMethod === "QR") {
            setShowPaymentPopup(false);
            FullCancerFormService.reset();
            window.location.replace(MBAGEAS_LIFE_URL);
          } else {
            _paymentWithBank();
          }
        }}
        onCancel={() => {
          setShowPaymentPopup(false);
        }}
        okTitle={
          paymentMethod === "QR" ? t("back-to-home") : t("continue-payment")
        }
      >
        <div className="fullWidth">
          <div className="line mb32" />
          <div className="smallTitle textBlack mb24">{t("payment-info")}</div>
          <div className="popupBody mb24">
            {t("ycbh-number")}: <span className="w600">{applNo}</span>
          </div>
          {FullCancerFormService.getFullCancerFormInfo()?.discountAmt &&
          FullCancerFormService.getFullCancerFormInfo()?.discount ? (
            <>
              <div className="rowSpaceBetween mb-3">
                <div className="popupBody">{t("insurance-fee")}:</div>
                <div className="popupBody w600">
                  {`${Utils.formatMoney(
                    FullCancerFormService.getFullCancerFormInfo()
                      ?.beforeDiscount
                  )} ${t("vnd")}`}
                </div>
              </div>
              <div className="rowSpaceBetween mb-3">
                <div className="popupBody">{t("discount-for-first-year")}:</div>
                <div className="popupBody w600">
                  {`-${Utils.formatMoney(
                    FullCancerFormService.getFullCancerFormInfo()?.discountAmt
                  )} ${t("vnd")}`}
                </div>
              </div>
              <div className="rowSpaceBetween mb-3">
                <div className="popupBody">{t("payment-amount")}:</div>
                <div className="popupBody w600">
                  {`${Utils.formatMoney(
                    FullCancerFormService.getFullCancerFormInfo()?.afterDiscount
                  )} ${t("vnd")}`}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="rowSpaceBetween mb-3">
                <div className="popupBody">{t("insurance-fee")}:</div>
                <div className="popupBody w600">
                  {selectedPackageData?.premiumAmTxt}
                </div>
              </div>
              <div className="rowSpaceBetween mb-3">
                <div className="popupBody">{t("payment-amount")}:</div>
                <div className="popupBody w600">
                  {selectedPackageData?.premiumAmTxt}
                </div>
              </div>
            </>
          )}

          <div className="popupBody italic">
            {Utils.getReplaceText(t("pay-insurance-hint"), _get24hLaterText())}
          </div>
          <div className="line mt32 mb32" />
          <div className="smallTitle textBlack mb24">{t("payment-method")}</div>
          <div className="popupBody mb-3">
            {t("please-choose-payment-method")}
          </div>
          <RadioItemSelector
            data={paymentMethodData}
            onChange={_handleChangePaymentMethod}
            value={paymentMethod}
          />
          {paymentMethod === "QR" ? _renderPaymentQr() : _renderPaymentBank()}
        </div>
      </PopupConfirm>
    );
  };

  return (
    <div className={styles.pageContainer}>
      {_renderPaymentPopup()}
      <OtpPopup
        phone={values.phone}
        value={otp}
        onChange={_handleChangeOtp}
        visible={showOtpPopup}
        onOk={_handleConfirmOtp}
        onCancel={_handleCancelOtp}
        key={"otpKey_" + otpKeyId}
        onResend={_handleResendOtp}
        errOtp={errOtp}
        actionText={
          FullCancerFormService.getFullCancerFormInfo()?.discount &&
          FullCancerFormService.getFullCancerFormInfo()?.afterDiscount === 0
            ? t("finish")
            : t("choose-payment-method")
        }
      />
      <Row className={`justify-content-center ${styles.stepper}`}>
        <Col lg={8}>
          <div className="rowCenter">
            <HorizontalStepper data={BUY_INSURANCE_STEP_DATA} step={2} />
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={6}>{_renderInfo()}</Col>
        <Col lg={3}>{_renderInsuranceItem()}</Col>
        {!isLargeDevice && <Col>{_renderButton()}</Col>}
      </Row>
    </div>
  );
};

export default ConfirmInfo;
