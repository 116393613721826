import { POLICY_STATUS, REPLACE_IDENTIFIER } from "src/Constants";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import SellerInfoService from "src/services/SellerInfoService";
import { Day } from "@hassanmojab/react-modern-calendar-datepicker";
import AuthInfoService from "src/services/AuthInfoService";
import { gaTrackEvent } from "src/api/ga-tracking";
dayjs.extend(customParseFormat);

export default class Utils {
  static isValidEmail(str: string) {
    if (!str) return false;
    let emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/g;
    let result = emailRegex.test(str);
    if (result === true) {
      if (str[str.indexOf("@") + 1] === "-" || str[str.length - 1] === "-") {
        return false;
      }
    }
    return result;
  }

  static isValidPhoneNumer(str: string) {
    if (!str) return false;
    if (str.length !== 10) return false;
    if (str[0] !== "0") return false;
    let phoneRegexStr = "^\\d{10}$";
    let phoneRegex = new RegExp(phoneRegexStr);
    return phoneRegex.test(str);
  }

  static removeAccent = (str: string): string => {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    return str;
  };

  static censorPhoneNumber = (phone: string) => {
    let splitArr = phone.split("");
    splitArr.splice(3, 3, "x", "x", "x");
    return splitArr.join("");
  };

  static censorGTTT = (gttt: string) => {
    let splitArr = gttt.split("");
    splitArr.splice(3, 2, "x", "x");
    return splitArr.join("");
  };

  static formatDate = (date: Date, format = "DD/MM/YYYY") => {
    return dayjs(date).format(format);
  };

  static covertToDate = (dateStr: string, format = "DD/MM/YYYY"): Date => {
    return dayjs(dateStr, format).toDate();
  };

  static covertToDateForInput = (
    dateStr: string,
    format = "YYYY-MM-DD"
  ): Date | null => {
    const inputDay = dayjs(dateStr, format);
    const todayDay = dayjs();
    if (todayDay.diff(inputDay, "year") < 100) {
      return dayjs(dateStr, format).toDate();
    }
    return null;
  };

  static getFileBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result as string);
      };
      reader.onerror = function (error) {
        gaTrackEvent(
          "TRACKING_OTP",
          "getFileBase64",
          JSON.stringify(error),
        );
        reject(error);
      };
    });
  };

  static getBase64Content = (base64: string) => {
    if (base64 === '') {
      return null;
    }
    let content = base64;
    if (content.includes('base64,')) {
      content = content.split('base64,')[1];
    }
    return content;
  };

  static getFileType = (file: File) => {
    if (file.name.toLowerCase().endsWith("pdf")) {
      return "PDF";
    }
    if (
      file.name.toLowerCase().endsWith("jpg") ||
      file.name.toLowerCase().endsWith("jpeg")
    ) {
      return "JPG";
    }
    if (file.name.toLowerCase().endsWith("png")) {
      return "PNG";
    }
    return "";
  };

  static getReplaceText = (
    text: string,
    replaceValue,
    replaceIdentifier?: string
  ): string => {
    return text.replace(replaceIdentifier || REPLACE_IDENTIFIER, replaceValue);
  };

  static getReplaceTextMulti = (
    text: string,
    replaceValue: string[],
    replaceIdentifier: string = REPLACE_IDENTIFIER
  ): string => {
    const splitTextArr = text.split(replaceIdentifier);
    console.log("Split arr", splitTextArr);
    let newText = "";
    for (let i = 0; i < splitTextArr.length; i++) {
      newText += splitTextArr[i];
      if (i < splitTextArr.length - 1) {
        newText += replaceValue[i];
      }
    }
    return newText;
  };

  static isValidUploadImageSize = (imageFile: File) => {
    return imageFile.size < 5242880;
  };

  static isValidImageFileType = (imageFile: File) => {
    const lowerCaseName = imageFile.name.toLowerCase();
    return (
      lowerCaseName.endsWith(".pdf") ||
      lowerCaseName.endsWith(".jpg") ||
      lowerCaseName.endsWith(".jpeg") ||
      lowerCaseName.endsWith(".png")
    );
  };

  static isValidCMND = (cmnd: string) => {
    if (!cmnd) return false;
    if (cmnd.length !== 9 && cmnd.length !== 12) return false;
    let cmndRegexStr = "^\\d{12}$";
    if (cmnd.length === 9) {
      cmndRegexStr = "^\\d{9}$";
    }
    let cmndRegex = new RegExp(cmndRegexStr);
    return cmndRegex.test(cmnd);
  };

  static isValidCCCD = (cccd: string) => {
    if (!cccd) return false;
    if (cccd.length !== 12) return false;
    let cccdRegexStr = "^\\d{12}$";
    let cccdRegex = new RegExp(cccdRegexStr);
    return cccdRegex.test(cccd);
  };

  static isValidPassport = (passport: string) => {
    const passportRegexStr = "^[a-zA-Z0-9]{6,}$";
    let passportRegex = new RegExp(passportRegexStr);
    return passportRegex.test(passport);
  };

  static isValidCMTQD = (cmtqd: string) => {
    const cmtqdRegexStr = "^[a-zA-Z0-9]{6,}$";
    let cmtqdRegex = new RegExp(cmtqdRegexStr);
    return cmtqdRegex.test(cmtqd);
  };

  static isNotContainSpecialCharacter = (text: string) => {
    const notContainSpecialCharacterRegexStr = "^[a-zA-Z0-9]+$";
    let notContainSpecialCharacterRegex = new RegExp(
      notContainSpecialCharacterRegexStr
    );
    return notContainSpecialCharacterRegex.test(text);
  };

  static formatPhoneNumber = (phone: string): string | null => {
    let cleaned = ("" + phone).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{3})(\d{4})(\d+)$/);
    if (match) {
      return `${match[1]} ${match[2]} ${match[3]}`;
    }
    return null;
  };
  static formatMoney = (str: any): string => {
    if (!isNaN(str)) {
      str = Math.floor(str);
    }
    str = (+str).toString();
    if (str == null || typeof str == "undefined" || str.length === 0) return "";

    if (str.length === 2 && str[1] === 0 && str[0] === 0) {
      return "0";
    }

    var temp = str;

    for (let i = 0; i < temp.length; i++) {
      if (temp[i] !== "0") {
        temp = temp.substr(i);
        if (temp.length > 1 && temp[0] === ".") {
          temp = temp.substr(1);
        }
        if (temp < 0) {
          return (
            "-" +
            temp
              .toString()
              .replace(/\D/g, "")
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
          );
          // console.log("temp ==", temp.toString().replace(/\D/g, '').replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1\."))
        } else {
          return temp
            .toString()
            .replace(/\D/g, "")
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        }
      }
    }
    if (str < 0) {
      return (
        "-" +
        str
          .toString()
          .replace(/\D/g, "")
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
      );
    } else {
      return str
        .toString()
        .replace(/\D/g, "")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }
  };

  static isSmartRMChannel = (): boolean => {
    return (
      (!!SellerInfoService.getSellerInfo()?.merchant?.code &&
        SellerInfoService.getSellerInfo()!.merchant!.code!.toUpperCase() ===
          "SMARTRM") ||
      SellerInfoService.getSellerInfo()!.merchant!.code!.toUpperCase() ===
        "SMRTKHCN"
    );
  };

  static getPolicyStatusText = (status: string): string => {
    if (status === POLICY_STATUS.LAPSE) {
      return "policy-status-lapse";
    }
    if (status === POLICY_STATUS.DOMART) {
      return "policy-status-domart";
    }
    if (status === POLICY_STATUS.REVERSED) {
      return "policy-status-reversed";
    }
    if (status === POLICY_STATUS.ACTIVE) {
      return "policy-status-active";
    }
    return status;
  };

  static convertDateToDay = (date: Date): Day => {
    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
  };

  static canBuyCaner = (): boolean => {
    return true;
    // return (
    //   !!SellerInfoService.getSellerInfo() || !!AuthInfoService.getChannel()
    // );
  };

  static getBuyCancerUrl = (): string => {
    if (
      SellerInfoService.getSellerInfo()?.merchant?.name?.toUpperCase() ===
      "MBBIZ"
    ) {
      return "/cancer/buy-insurance/personal-info";
    }
    if (SellerInfoService.getSellerInfo()) {
      return "/merchant/sell-insurance/cancer";
    }
    return "/individual/buy-insurance/cancer";
  };
}
